import React from 'react';

import Container from '../container/Container.component';
import kenImg from '../../assets/images/ken.png';
import brianImg from '../../assets/images/brian.png';
import kentImg from '../../assets/images/kenth.png';
import franzImg from '../../assets/images/franz.png';
import ContentTitle from '../ContentTitle';
import ContentText from '../ContentText';

import Animate from '../Animate';

import './our-team.styles.css';

const OurTeam = () => {

    return (
        <div id="offer">
            <Container>
                <div className="offer-inner">
                    <div className="title-container">
                        <Animate name="fadeInUp" className="title offer-title">
                            EAST OFFICERS
                        </Animate>
                    </div>
                    <div className="offer-content">
                        <Animate name="fadeInRight" className="offer-content-inner border-left">
                            <div className="content-info">
                                <div className="logo logo-top"><img src={franzImg} alt="" className="logo-img"/></div>
                                <ContentTitle className="offer-title" title="FRANZ UBAS" />
                                <ContentText>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu purus a risus euismod suscipit vitae non leo. Fusce fringilla volutpat diam, luctus blandit neque pretium sed.</ContentText>
                            </div>
                        </Animate>
                        <Animate name="fadeInLeft" className="offer-content-inner border-left">
                            <div className="content-info">
                                <div className="logo logo-top"><img src={kenImg} alt="" className="logo-img"/></div>
                                <ContentTitle className="offer-title" title="KEN P. MISA"/>
                                <ContentText>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu purus a risus euismod suscipit vitae non leo. Fusce fringilla volutpat diam, luctus blandit neque pretium sed.</ContentText>
                            </div>
                        </Animate>
                    </div>
                    <div className="offer-content">
                        <Animate name="fadeInLeft" className="offer-content-inner border-left">
                            <div className="content-info">
                                <div className="logo logo-top"><img src={kentImg} alt="" className="logo-img"/></div>
                                <ContentTitle className="offer-title" title="KENTH DELA RAMA"/>
                                <ContentText>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu purus a risus euismod suscipit vitae non leo. Fusce fringilla volutpat diam, luctus blandit neque pretium sed.</ContentText>
                            </div>
                        </Animate>
                        <Animate name="fadeInRight" className="offer-content-inner border-left">
                            <div className="content-info">
                                <div className="logo logo-top"><img src={brianImg} alt="" className="logo-img"/></div>
                                <ContentTitle className="offer-title" title="BRIAN A. ORTIZ"/>
                                <ContentText>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu purus a risus euismod suscipit vitae non leo. Fusce fringilla volutpat diam, luctus blandit neque pretium sed.</ContentText>
                            </div>
                        </Animate>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default OurTeam;
