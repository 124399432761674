import React from 'react';

import Container from '../container/Container.component';

import { MdLocationOn, MdPhone,MdEmail } from 'react-icons/md';

import './style.css';

const Footer:React.FC = () => {
    return (
        <footer id="footer">
            <Container>
                <div className="footer-inner">
                    <div className="footer-link-container">
                        <div className="footer-link">
                            <a href="https://www.facebook.com/groups/336521400135284" target="_self" rel="referrer noopener" className="link">YCSK (TEAM CEBU)</a>
                            <a href="https://www.facebook.com/YCSK-EAST-492793477792244" target="_self" rel="referrer noopener" className="link">YCSK (EAST)</a>
                            <a href="https://www.facebook.com/groups/376749416345366" target="_self" rel="referrer noopener" className="link">YCSK ( NORTH KINGS )</a>
                        </div>
                        <div className="footer-link info-link">
                            <div className="info">
                                <MdLocationOn  className="info-logo first"/>
                                <p className="info-text">
                                    Mactan Shrine Lapu-Lapu City, Cebu
                                </p>
                            </div>
                            <div className="info">
                                <MdPhone  className="info-logo second"/>
                                <p className="info-text">
                                    +63 936 962 9433
                                </p>
                            </div>
                            <div className="info">
                                <MdEmail  className="info-logo third"/>
                                <p className="info-text">
                                    ycsk-east@gmail.com
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Container>
                <div className="footer-end">
                    All right reserve ycsk-east.com &copy; 2020
                </div>
            </Container>
        </footer>
    )
}

export default Footer;
