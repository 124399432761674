import React from 'react';

import Container from '../container/Container.component';
import ContentTitle from '../ContentTitle';
import ContentText from '../ContentText';
import Animate from '../Animate';

import { FaUsers, FaFileAlt, FaMotorcycle } from "react-icons/fa";

import './about.styles.css';


const About:React.FC = () => {

    return (
        <div id="about">
            <Container>
                <div className="about-inner">
                    <div className="title-container">
                        <Animate name="fadeInUp" className="title">about us</Animate>
                    </div>
                    <div className="about-content">
                        <Animate name="fadeInLeft" className="content-info margin-40">
                            <div className="logo"><FaUsers className="logo-img"/></div>
                            <ContentTitle title="EXTRAORDINARY GROUP" />
                            <ContentText>
                                A group of Yamaha Sniper MX150i motorcycle brand with quality, responsible and respectful members from all walks of life
                            </ContentText>
                        </Animate>
                        <Animate name="fadeInUp" className="content-info margin-40">
                            <div className="logo"><FaMotorcycle className="logo-img"/></div>
                            <ContentTitle title="RIDES AND CHARITY EVENTS" />
                            <ContentText>
                                Ride with us in the different islands of the Philippines.
                                <br/>
                                And charity event for the needy, environment and for the riding community.
                            </ContentText>
                        </Animate>
                        <Animate name="fadeInRight" className="content-info margin-40">
                            <div className="logo"><FaFileAlt className="logo-img"/></div>
                            <ContentTitle title="ORIENTED RIDERS" />
                            <ContentText>
                                Traffic rules are followed and understand for the safety of every rider
                            </ContentText>
                        </Animate>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default About;
