import React, { useContext } from 'react';

import { BoundingRectTop } from '../../App';

import Container from '../container/Container.component';

import './banner.styles.css';
import { Link } from 'react-scroll';

const Banner = () => {
    const { setDistance } = useContext(BoundingRectTop);
    
    window.addEventListener("scroll", () => {
        const banner = document.querySelector("#banner").getBoundingClientRect().top;
        setDistance(banner)
    })
    
    return (
        <div id="banner">
            <div className="banner-background"></div>
            <Container>
                <div className="banner-inner">
                    <div className="banner-title">
                        YCSK EAST CHAPTER
                    </div>
                    <div className="banner-text">
                        A group of Yamaha Sniper MX150i motorcycle brand of cebu east chapter
                    </div>
                    <div className="banner-footer">
                        <Link activeClass="active" to="contact" spy={true} smooth={true} offset={0}>
                            <button className="banner-button">
                                JOIN US
                            </button>
                        </Link>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Banner;